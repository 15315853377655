import { getPlanWebDescription } from '@pumpkincare/plans';
import { sortLegalData } from '@pumpkincare/quotes';
import { removeDuplicates } from '@pumpkincare/shared';

export function compileLegalData(pets) {
  let allLegal = pets.map(pet => {
    return pet.plan ? getPlanWebDescription(pet.plan) : { legal: [] };
  });

  allLegal = allLegal.reduce((a, b) => {
    return { legal: [...a.legal, ...b.legal] };
  });
  allLegal = removeDuplicates(allLegal.legal, 'content');

  return sortLegalData(
    allLegal.filter(legal => !legal.title.includes('PRESCRIPTION'))
  );
}

export function isValidCustomerName(name) {
  const numbersRegex = /\d/g;
  const lettersRegex = /[a-zA-Z]/g;

  return (
    !numbersRegex.test(name) &&
    !name.includes('Pet Parent') &&
    isFieldFilledOut(name) &&
    lettersRegex.test(name)
  );
}

export function isFieldFilledOut(value) {
  return !!value;
}

export function addressReduxTransform(addressRedux) {
  return {
    country: addressRedux?.country?.value,
    state: addressRedux?.state?.value,
    city: addressRedux?.city?.value,
    street_1: addressRedux?.address1?.value,
    street_2: addressRedux?.address2?.value,
    zipcode: addressRedux?.zipcode?.value,
  };
}
