import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Body1, Body2, Superscript2, Typography } from '@pumpkincare/shared/ui';

import styles from './plan-statistics.css';

function PlanStatistics({ whyPetInsurance, preExConditions, state, royaltyData }) {
  const { handle, handleLocation, imageUrl, items } = whyPetInsurance;

  const {
    enabled,
    description: royaltyDesc,
    imageUrl: royaltyImage = '',
    items: royaltyItems = [],
  } = royaltyData;

  const isBreedDefinition = enabled;

  function handleSupScriptClick() {
    setTimeout(() => {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }, 100);
  }
  const containerClassName = classNames(styles.container, {
    [styles.containerBreed]: !isBreedDefinition,
  });

  const contentClassName = classNames({
    [styles.content]: !isBreedDefinition,
    [styles.contentBreed]: isBreedDefinition,
  });

  const imageItemClassName = classNames({
    [styles.imageItem]: !isBreedDefinition,
    [styles.imageItemBreed]: isBreedDefinition,
  });

  const stethoClassName = classNames(styles.stethoItem, {
    [styles.justifyRight]: isBreedDefinition,
    [styles.justifyCenter]: !isBreedDefinition,
  });

  const preExistingClassName = classNames(styles.preExistingItem, {
    [styles.justifyLeft]: isBreedDefinition,
    [styles.justifyCenter]: !isBreedDefinition,
    [styles.preExistingItemBreed]: !isBreedDefinition,
  });

  const itemImgClassName = classNames({
    [styles.itemImgBreed]: isBreedDefinition,
    [styles.itemImg]: !isBreedDefinition,
  });

  const itemTextClassName = classNames(styles.itemText, {
    [styles.itemTextBreed]: isBreedDefinition,
  });
  return (
    <div className={containerClassName}>
      <h3 className={Typography.h3}>Why Pumpkin Pet Insurance?</h3>

      {enabled ? (
        <div className={styles.containerRoyalty}>
          <h5 className={classNames(Typography.h5, styles.titleSection)}>
            {royaltyDesc}
          </h5>
          <div className={styles.copySection}>
            {royaltyItems?.map((item, index) => (
              <div className={styles.royalItem} key={index}>
                <img src='/assets/images/iconImages/shape-star.svg' alt='' />

                <div>
                  <Body1 isBold>{item.title}</Body1>

                  <Body2>{item.description}</Body2>
                </div>
              </div>
            ))}
          </div>

          <div className={styles.imageSection}>
            <img src={royaltyImage} alt='' />
          </div>
        </div>
      ) : null}

      <div className={contentClassName}>
        <h5
          className={classNames(
            Typography.h5,
            styles.moduleHeader,
            styles.gridSubTitle
          )}
        >
          {handleLocation ?? handle}
        </h5>

        <div className={classNames(styles.emergencyItem, styles.item)}>
          <img
            src='/assets/images/iconImages/siren-pluto-gold.png'
            alt=''
            className={itemImgClassName}
          />

          <div className={itemTextClassName}>
            <span className={classNames(Typography.bold, styles.itemTitle)}>
              {items[0]?.title}{' '}
              <Superscript2
                className={styles.supScript}
                onClick={handleSupScriptClick}
              >
                2
              </Superscript2>
            </span>

            <Body1 className={styles.itemSubTitle}>{items[0]?.copy}</Body1>
          </div>
        </div>

        <div className={classNames(styles.dnaItem, styles.item)}>
          <img
            src='/assets/images/iconImages/dna-clifford-red.png'
            alt=''
            className={itemImgClassName}
          />

          <div className={itemTextClassName}>
            <span className={classNames(Typography.bold, styles.itemTitle)}>
              {items[3]?.title}
            </span>

            <Body1 className={styles.itemSubTitle}>{items[3]?.copy}</Body1>
          </div>
        </div>

        <div className={classNames(styles.savingItem, styles.item)}>
          <img
            src='/assets/images/iconImages/savings-benji-blue.png'
            alt=''
            className={itemImgClassName}
          />

          <div className={itemTextClassName}>
            <span className={classNames(Typography.bold, styles.itemTitle)}>
              {items[2]?.titleLocation?.[state] ?? items[2]?.title}

              <Superscript2
                className={styles.supScript}
                onClick={handleSupScriptClick}
              >
                4
              </Superscript2>
            </span>

            <Body1 className={styles.itemSubTitle}>
              {items[2]?.titleLocation?.[state]
                ? items[2]?.copyLocation
                : items[2]?.copy}
            </Body1>
          </div>
        </div>

        <div className={classNames(stethoClassName, styles.item)}>
          <img
            src='/assets/images/iconImages/stethoscope-clifford-red.png'
            alt=''
            className={itemImgClassName}
          />

          <div className={itemTextClassName}>
            <span className={classNames(Typography.bold, styles.itemTitle)}>
              {items[1]?.title}{' '}
              <Superscript2
                className={styles.supScript}
                onClick={handleSupScriptClick}
              >
                3
              </Superscript2>
            </span>

            <Body1 className={styles.itemSubTitle}> {items[1]?.copy}</Body1>
          </div>
        </div>

        <div className={classNames(preExistingClassName, styles.item)}>
          <img
            src='/assets/images/iconImages/pre-existing-pluto-gold.png'
            alt=''
            className={itemImgClassName}
          />

          <div className={itemTextClassName}>
            <span className={classNames(Typography.bold, styles.itemTitle)}>
              It’s best to sign up before pre-existing conditions pile up!
            </span>

            <Body1 className={styles.itemSubTitle}>{preExConditions}</Body1>
          </div>
        </div>

        <div className={imageItemClassName}>
          <div className={styles.petImage}>
            <img src={imageUrl} alt='' />
          </div>
        </div>
      </div>
    </div>
  );
}

PlanStatistics.defaultProps = {
  whyPetInsurance: {},
  preExConditions: '',
  state: '',
  royaltyData: {},
};

PlanStatistics.propTypes = {
  whyPetInsurance: PropTypes.shape({
    handle: PropTypes.string,
    handleLocation: PropTypes.string,
    imageUrl: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        copy: PropTypes.string,
        imageUrl: PropTypes.string,
        titleLocation: PropTypes.object,
        copyLocation: PropTypes.string,
      })
    ),
  }),
  preExConditions: PropTypes.string,
  state: PropTypes.string,
  royaltyData: PropTypes.shape({
    description: PropTypes.string,
    imageUrl: PropTypes.string,
    items: PropTypes.array,
  }),
};

export default PlanStatistics;
