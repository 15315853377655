// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pet-questions_subtitle_22a8167b {\n  margin-bottom: 16px;\n  text-align: center;\n}", "",{"version":3,"sources":["webpack://src/quotes/view/pet-questions/pet-questions.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":[".subtitle {\n  margin-bottom: 16px;\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subtitle": "pet-questions_subtitle_22a8167b"
};
export default ___CSS_LOADER_EXPORT___;
