// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yes-no_group_614784b4 {\n    width: 100%;\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    justify-content: center;\n}\n\n.yes-no_group_614784b4 .yes-no_groupItem_7437a44f {\n    width: 128px;\n    height: 128px;\n    margin: 4px;\n\n    box-sizing: border-box;\n}", "",{"version":3,"sources":["webpack://src/quotes/view/pet-questions/yes-no/yes-no.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,WAAW;;IAEX,sBAAsB;AAC1B","sourcesContent":[".group {\n    width: 100%;\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    justify-content: center;\n}\n\n.group .groupItem {\n    width: 128px;\n    height: 128px;\n    margin: 4px;\n\n    box-sizing: border-box;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"group": "yes-no_group_614784b4",
	"groupItem": "yes-no_groupItem_7437a44f"
};
export default ___CSS_LOADER_EXPORT___;
