import classNames from 'classnames';

import styles from './sticky-bar.css';

function StickyBar({ children, visibility = true, onClick }) {
  return (
    <div
      className={classNames(styles.root, { [styles.visible]: visibility })}
      data-testid={'sticky-bar'}
    >
      <div className={styles.overlay} onClick={onClick} data-testid={'backdrop'}>
        &nbsp;
      </div>
      {children}
    </div>
  );
}

export default StickyBar;
