import { LegalBody } from '@pumpkincare/shared/ui';

import styles from './one-step-closer.module.css';

export default function OneStepCloser() {
  return (
    <div className={styles.oneStepCloserContainer}>
      <LegalBody>
        You're one step closer to helping your pet get the best care pawsible!
      </LegalBody>
      <img
        alt='One More Step'
        src='assets/images/cartoons/PumpkinBox_2pets.svg'
        className={styles.image}
      />
    </div>
  );
}
