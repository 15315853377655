// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".multiple-choice_group_920cac24 {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    flex-wrap: nowrap;\n    justify-content: center;\n}\n\n.multiple-choice_group4x4_6ce752a9 {\n    flex-direction: row;\n    flex-wrap: wrap;\n}\n\n.multiple-choice_group_920cac24 .multiple-choice_groupItem4x4_7a4d78c6 {\n    width: 128px;\n    height: 128px;\n    margin: 4px;\n\n    box-sizing: border-box;\n}\n\n.multiple-choice_group_920cac24 .multiple-choice_groupItem_4ddb896f {\n    width: auto;\n    margin: 8px 0;\n    align-items: flex-start;\n}", "",{"version":3,"sources":["webpack://src/quotes/view/pet-questions/multiple-choice/multiple-choice.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,iBAAiB;IACjB,uBAAuB;AAC3B;;AAEA;IACI,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,WAAW;;IAEX,sBAAsB;AAC1B;;AAEA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;AAC3B","sourcesContent":[".group {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    flex-wrap: nowrap;\n    justify-content: center;\n}\n\n.group4x4 {\n    flex-direction: row;\n    flex-wrap: wrap;\n}\n\n.group .groupItem4x4 {\n    width: 128px;\n    height: 128px;\n    margin: 4px;\n\n    box-sizing: border-box;\n}\n\n.group .groupItem {\n    width: auto;\n    margin: 8px 0;\n    align-items: flex-start;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"group": "multiple-choice_group_920cac24",
	"group4x4": "multiple-choice_group4x4_6ce752a9",
	"groupItem4x4": "multiple-choice_groupItem4x4_7a4d78c6",
	"groupItem": "multiple-choice_groupItem_4ddb896f"
};
export default ___CSS_LOADER_EXPORT___;
