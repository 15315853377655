/**
 * Pass MOCKS flag (false by default) to your start script to control HTTP mocks
 * https://mswjs.io/docs/api/setup-worker
 *
 * > MOCKS=true yarn start
 */
// import { setupWorker } from 'msw';

// if (MOCKS) {
//   const worker = setupWorker(/* pass request handlers here */);
//
//   worker.start();
// }

import AppController from './app-shell/app-controller';

AppController();
