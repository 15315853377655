import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import Store from 'store';

import {
  analyticsTrack,
  getTrackedCustomProps,
  QUOTE_FLOW,
} from '@pumpkincare/analytics';
import { MC_URL } from '@pumpkincare/config';
import { patchIdentity } from '@pumpkincare/identity';
import { setMarketingAttributionProperty } from '@pumpkincare/marketing';
import {
  getQuoteId,
  getQuotePets,
  QUOTE_ID_COOKIE_NAME,
  transformFinalize,
  useFinalize,
  useQuote,
} from '@pumpkincare/quotes';
import {
  BannerType,
  COMPLETE_QUOTE_ID_LS_KEY,
  currentISODate,
  DUPLICATE_PET_ERROR,
  GENERIC_PAYMENT_ERROR,
  getCookie,
  getIsLoggedIn,
  ITERABLE_CAMPAIGN_ID,
  ITERABLE_TEMPLATE_ID,
  navigateTo,
  postBillingAddressValidate,
  QUOTE_URL_PARAMS_KEY,
  removeCookie,
  useBanners,
} from '@pumpkincare/shared';

import { getAppAgentId, Paths } from '../../../app-shell';
import { getIdentityId } from '../../../identity';
import { resetQuotes } from '../../../quotes';
import {
  getQuotes,
  getQuotesBillingAddress,
  getQuotesShippingAddress,
  getShippingSameAsBilling,
} from '../../../quotes/selectors';
import {
  useIsChargedAnnuallyValue,
  usePaperLessValue,
  useTotalsValue,
} from '../checkout-provider';
import { addressReduxTransform } from './checkout-utils';

export default function useSubmitCheckout() {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  const dispatch = useDispatch();
  const agentId = useSelector(getAppAgentId);
  const billingAddress = useSelector(getQuotesBillingAddress);
  const identityId = useSelector(getIdentityId);
  const quotes = useSelector(getQuotes);
  const shippingAddress = useSelector(getQuotesShippingAddress);
  const isSameAsShipping = useSelector(getShippingSameAsBilling);

  const { addBanner, removeAllBanners } = useBanners();
  const { grandTotal } = useTotalsValue();
  const isChargedAnnually = useIsChargedAnnuallyValue();
  const { termsVersion, isPaperLess } = usePaperLessValue();
  const [isLoading, setIsLoading] = useState(false);

  const { data: quoteData } = useQuote();
  const pets = getQuotePets(quoteData);
  const quoteId = getQuoteId(quoteData);

  const { mutateAsync: finalize } = useFinalize();

  function submitCheckout() {
    const iterableCampaignId = getCookie(ITERABLE_CAMPAIGN_ID);
    const iterableTemplateId = getCookie(ITERABLE_TEMPLATE_ID);

    return Promise.all([
      stripe.createToken(elements.getElement(CardNumberElement)).then(payload => {
        analyticsTrack(
          {
            category: QUOTE_FLOW,
            event: 'Submit Payment',
            label: payload.error ? 'Failed' : 'Success',
          },
          getTrackedCustomProps()
        );

        return payload.error ? Promise.reject() : Promise.resolve(payload.token.id);
      }),

      postBillingAddressValidate(
        addressReduxTransform(
          isSameAsShipping
            ? { country: { value: 'US' }, ...shippingAddress }
            : billingAddress
        )
      ),
    ])
      .then(([stripeToken]) => {
        const postBody = transformFinalize(
          stripeToken,
          quotes,
          { agentId },
          isChargedAnnually,
          termsVersion,
          isPaperLess
        );

        return finalize({ quoteId, postBody });
      })
      .then(result => {
        const {
          token: { access_token },
          user,
        } = result;
        const { email } = user;

        analyticsTrack({
          event: 'Order Completed',
          email,
          total: parseFloat(grandTotal),
          campaignId: iterableCampaignId
            ? parseInt(iterableCampaignId)
            : iterableCampaignId,
          templateId: iterableTemplateId
            ? parseInt(iterableTemplateId)
            : iterableTemplateId,
          dataFields: {},
        });
        analyticsTrack(
          {
            category: 'Purchase Event',
            event: 'Finalize Success',
            label: `Policies: ${pets.length}, Plans: ${
              pets.filter(pet => !!pet.hasPrevent).length
            }`,
            created_at: currentISODate(),
          },
          getTrackedCustomProps()
        );

        setMarketingAttributionProperty({ isGoingToCheckoutSuccess: true });
        removeAllBanners();
        dispatch(resetQuotes());
        Store.remove(QUOTE_URL_PARAMS_KEY);
        Store.set(COMPLETE_QUOTE_ID_LS_KEY, getCookie(QUOTE_ID_COOKIE_NAME));
        removeCookie(QUOTE_ID_COOKIE_NAME);

        history.push(
          Paths.Hdyhau +
            '?' +
            new URLSearchParams({ email, token: access_token }).toString()
        );
      });
  }

  function submitCheckoutApld() {
    return finalize({ quoteId, postBody: {} }).then(() => {
      Store.set(COMPLETE_QUOTE_ID_LS_KEY, getCookie(QUOTE_ID_COOKIE_NAME));
      removeCookie(QUOTE_ID_COOKIE_NAME);

      dispatch(resetQuotes());

      navigateTo(MC_URL);
    });
  }

  function submit() {
    setIsLoading(true);

    patchIdentity({
      id: identityId,
      city: shippingAddress.city.value,
      state_province: shippingAddress.state.value,
      zipcode: shippingAddress.zipcode.value,
      first_name: shippingAddress.firstName.value,
      last_name: shippingAddress.lastName.value,
    });

    return (getIsLoggedIn() ? submitCheckoutApld() : submitCheckout())
      .catch(error => {
        if (error?.response?.status === 409) {
          addBanner(DUPLICATE_PET_ERROR);
        } else if (error?.response?.status === 422) {
          addBanner({
            type: BannerType.ERROR,
            title: 'Billing Address Error:',
            message:
              error.response.data.errors?.zipcode ??
              error.response.data.message ??
              'State does not match the selected zip code',
          });
        } else {
          addBanner(GENERIC_PAYMENT_ERROR);
        }
      })
      .finally(() => setIsLoading(false));
  }

  return { submit, isLoading };
}
