import classNames from 'classnames';

import { formatLargeNum } from '@pumpkincare/shared';
import { Body2, ButtonStyles } from '@pumpkincare/shared/ui';

import styles from './sticky-cta.css';

function StickyCta({ pet, price, visibility = true, onClick, onCtaClick }) {
  const { annual_limit: annualLimit, deductible } = pet;

  return (
    <div
      className={classNames(styles.root, { [styles.visible]: visibility })}
      onClick={onClick}
    >
      <Body2 className={styles.levers}>
        90%<span className={styles.hideOnMobile}>&nbsp;Reimbursement Rate</span>{' '}
        <span className={styles.huckBlue}>|</span> {isNaN(annualLimit) ? '' : '$'}
        {formatLargeNum(annualLimit ?? 0)}
        <span className={styles.hideOnMobile}>&nbsp;Annual Coverage Limit</span>{' '}
        <span className={styles.huckBlue}>|</span> ${deductible ?? 0}
        <span className={styles.hideOnMobile}>&nbsp;Annual Deductible</span>
      </Body2>

      <button
        className={classNames(ButtonStyles.primary, styles.ctaButton)}
        onClick={onCtaClick}
      >
        Select<span className={styles.hideOnMobile}>&nbsp;This Plan</span> – ${price}{' '}
        / mo
      </button>
    </div>
  );
}

export default StickyCta;
