import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Body2, ButtonStyles, Typography } from '@pumpkincare/shared/ui';

import styles from './plan-whats-covered.css';

import favIcon from './favicon-benji-blue.svg';

function PlanWhatsCovered({ coverageDetails, onCompareClick }) {
  /*
    TODO: As soon as we remove punks947PlanPageDesignOptimization Feature Flags we should do the following:
    - delete the co-located favicon - /assets/images/Favicon_Blue_Clues.svg'
    - move the favicon to assets
    - refactor classNames related
  */
  const { punks947PlanPageDesignOptimization } = useFlags();

  const sectionTitle = punks947PlanPageDesignOptimization
    ? 'What’s Covered'
    : coverageDetails.header;

  const ctaIcon = punks947PlanPageDesignOptimization
    ? favIcon
    : '/assets/images/Favicon_Blue_Clues.svg';

  const ctaClassName = classNames(styles.compareCTA, {
    [styles.whiteCTA]: !punks947PlanPageDesignOptimization,
    [styles.blueCTA]: punks947PlanPageDesignOptimization,
  });

  const ctaCopyClassName = punks947PlanPageDesignOptimization
    ? classNames(styles.ctaCopy)
    : classNames(Typography.body2, Typography.bold);

  const ctaArrowClass = classNames({
    [styles.ctaArrow]: punks947PlanPageDesignOptimization,
  });

  const compareBtnClass = classNames(styles.link, ButtonStyles.cta, {
    [styles.ctaButton]: punks947PlanPageDesignOptimization,
  });

  return (
    <div className={styles.container}>
      <h2 className={Typography.h2}>{sectionTitle}</h2>

      {punks947PlanPageDesignOptimization ? (
        <h5 className={classNames(styles.subtitle, Typography.h5)}>
          {coverageDetails.coveredSubheader}
        </h5>
      ) : (
        <h4 className={classNames(styles.subtitle, Typography.h4)}>
          {coverageDetails.coveredSubheader}
        </h4>
      )}
      <div className={styles.iconsWrapper}>
        <div className={styles.iconsSection}>
          {coverageDetails.insurance.map((item, title) => (
            <div key={title} className={styles.iconItem}>
              <div className={styles.iconContainer}>
                <img src={item.imageUrl} alt='' />
              </div>

              <div>
                <Body2 isBold dangerouslySetInnerHTML={{ __html: item.title }} />

                <Body2 dangerouslySetInnerHTML={{ __html: item.copy }} />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.borderedIconsWrapper}>
        <div className={styles.iconsSection}>
          {coverageDetails?.unique_insurance?.map((item, title) => (
            <div key={title} className={styles.iconItem}>
              <div className={styles.iconContainer}>
                <img src={item.imageUrl} alt='' />
              </div>

              <div>
                <Body2 isBold dangerouslySetInnerHTML={{ __html: item.title }} />

                <Body2 dangerouslySetInnerHTML={{ __html: item.copy }} />
              </div>
            </div>
          ))}
        </div>

        <div className={ctaClassName}>
          <img src={ctaIcon} alt='' />

          <span className={ctaCopyClassName}>
            Pumpkin plans cover these, but some insurances don’t!
            <span className={compareBtnClass} onClick={onCompareClick} role='button'>
              Compare
              <img
                className={ctaArrowClass}
                src='/assets/images/iconImages/arrow-right-blue-clues.svg'
                alt=''
              />
            </span>
          </span>
        </div>
      </div>

      <h5 className={classNames(Typography.h5, styles.sectionTitle)}>
        {coverageDetails.vetSubheader}
      </h5>

      <div className={styles.iconsWrapper}>
        <div className={styles.iconsSection}>
          {coverageDetails.vet.map((item, title) => (
            <div key={title} className={styles.iconItem}>
              <div className={styles.iconContainer}>
                <img src={item.imageUrl} alt='' />
              </div>

              <div>
                <Body2 isBold dangerouslySetInnerHTML={{ __html: item.title }} />

                <Body2 dangerouslySetInnerHTML={{ __html: item.copy }} />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.borderedIconsWrapper}>
        <div className={styles.iconsSection}>
          {coverageDetails?.unique_vet?.map((item, title) => (
            <div key={title} className={styles.iconItem}>
              <div className={styles.iconContainer}>
                <img src={item.imageUrl} alt='' />
              </div>

              <div>
                <Body2 isBold dangerouslySetInnerHTML={{ __html: item.title }} />

                <Body2 dangerouslySetInnerHTML={{ __html: item.copy }} />
              </div>
            </div>
          ))}
        </div>

        <div className={ctaClassName}>
          <img src={ctaIcon} alt='' />

          <span className={ctaCopyClassName}>
            Pumpkin plans cover these, but some insurances don’t!
            <span className={compareBtnClass} onClick={onCompareClick} role='button'>
              Compare
              <img
                className={ctaArrowClass}
                src='/assets/images/iconImages/arrow-right-blue-clues.svg'
                alt=''
              />
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}

PlanWhatsCovered.defaultProps = {
  coverageDetails: {
    insurance: [],
    header: '',
    unique_insurance: [],
  },
};

export default PlanWhatsCovered;
