import { formatCurrency } from '@pumpkincare/shared';
import { Body1, Body2, LegalBody, Typography } from '@pumpkincare/shared/ui';

import BillingInfo from '../../../../billing-info';
import { useTotalsValue } from '../../../../checkout-provider';

import styles from './total-fee.css';

function TotalFee({ transactionFee, isAnnualCharged }) {
  const { insuranceTotal, preventTotal, grandTotal } = useTotalsValue();
  const frequencyPrefix = isAnnualCharged ? 'Yearly' : 'Monthly';

  const insuranceTotalPeriod = insuranceTotal;

  return (
    <div className={styles.root}>
      <h4>Plan Payment Summary</h4>

      <div className={styles.left}>
        <Body1 isBold>PET INSURANCE</Body1>
      </div>

      <div className={styles.spaceBetween} style={{ margin: '16px 0' }}>
        <LegalBody isBold className={styles.left}>
          {frequencyPrefix} Insurance Cost
        </LegalBody>

        <LegalBody isBold className={styles.right}>
          {formatCurrency(insuranceTotalPeriod)}
        </LegalBody>
      </div>

      {isAnnualCharged ? null : (
        <div className={styles.spaceBetween} style={{ margin: '0' }}>
          <LegalBody isBold className={styles.left}>
            Monthly Installment Fee
          </LegalBody>

          <LegalBody isBold className={styles.right}>
            {formatCurrency(transactionFee)}
          </LegalBody>
        </div>
      )}

      <BillingInfo isAnnualCharged={isAnnualCharged} />

      {isAnnualCharged ? null : (
        <div className={styles.spaceBetween} style={{ marginBottom: '48px' }}>
          <LegalBody isBold className={styles.left}>
            Total {frequencyPrefix} Insurance Cost
          </LegalBody>

          <LegalBody isBold className={styles.totalFeePrice}>
            {formatCurrency(insuranceTotalPeriod + transactionFee)}
          </LegalBody>
        </div>
      )}

      {preventTotal ? (
        <>
          <div className={styles.left}>
            <Body1 isBold>PLAN UPGRADES</Body1>
          </div>

          <div className={styles.spaceBetween} style={{ margin: '16px 0' }}>
            <LegalBody isBold className={styles.left}>
              {frequencyPrefix} Preventive Essentials Cost
            </LegalBody>

            <LegalBody isBold className={styles.right}>
              ${preventTotal.toFixed(2)}
            </LegalBody>
          </div>
        </>
      ) : null}

      <hr style={{ margin: 0 }} />

      <div>
        <div className={styles.totalSpacing} style={{ margin: '24px 0' }}>
          <div className={styles.totalAlignment}>
            <Body2 isBold>
              <span>{frequencyPrefix} Plan Grand Total</span>

              <div className={styles.divThickLine} />
            </Body2>

            <div className={Typography.h4}>
              <span>{formatCurrency(grandTotal)}</span>

              <div className={styles.divThickLinePrice} />
            </div>
          </div>

          <LegalBody className={styles.detailedTotalProducts}>
            (Includes {insuranceTotal ? 'Insurance' : null}
            {preventTotal ? ' + Preventive Essentials' : null})
          </LegalBody>
        </div>
      </div>
    </div>
  );
}

export default TotalFee;
