import classNames from 'classnames';
import PropTypes from 'prop-types';

import { formatCurrency } from '@pumpkincare/shared';
import { LegalBody, Numbers1, Typography } from '@pumpkincare/shared/ui';

import styles from './plan-calculator.css';

function PlanCalculator({ data, values }) {
  const { copy, photoUrl, iconUrl } = data;
  const { annual_limit: annualLimit, deductible, copay } = values;

  const coverageVetBill = 3500;
  const reimbursementRate = 100 - copay;
  const calculated = coverageVetBill - deductible;
  const cashBack = (calculated / 100) * reimbursementRate;
  const annualLimitBalanceRemaining =
    annualLimit === 'Unlimited'
      ? 'Unlimited'
      : formatCurrency(annualLimit - cashBack, { hideZero: true });

  return (
    <div className={styles.container} data-testid={'calc-container'}>
      <h3 className={styles.headerText}>
        See your recommended Pumpkin plan in action.
      </h3>

      <div className={styles.content} data-testid={'calc-content'}>
        <div className={styles.infoSection}>
          <div className={styles.infoImageWrapper}>
            <img src={iconUrl} className={styles.infoSectionImg} alt='' />
          </div>

          <div className={styles.copySection}>
            <span
              className={Typography.body1}
              dangerouslySetInnerHTML={{ __html: copy }}
            />
          </div>
        </div>

        <div className={styles.calcSection}>
          <div className={styles.photoUrlContainer}>
            <img src={photoUrl} className={styles.photoUrl} alt='' />
          </div>

          <div className={styles.calcSectionWrapper}>
            <div className={styles.numbersWrapper}>
              <div className={styles.regularSection}>
                <Numbers1 isBold>
                  {formatCurrency(coverageVetBill, { hideZero: true })}
                </Numbers1>

                <LegalBody>Covered Vet Bill</LegalBody>
              </div>

              <div className={styles.deductibleValue}>
                <span className={Typography.h4}>—</span>

                <div className={styles.regularSection}>
                  <Numbers1>
                    {formatCurrency(deductible, { hideZero: true })}
                  </Numbers1>

                  <LegalBody>Annual Deductible</LegalBody>
                </div>
              </div>

              <div className={styles.resultsSection}>
                <div className={styles.regularSection}>
                  <Numbers1>{`${reimbursementRate}%`}</Numbers1>

                  <LegalBody style={{ textAlign: 'right' }}>
                    Reimbursement Rate
                  </LegalBody>
                </div>

                <span className={Typography.h4}>of</span>

                <Numbers1>{formatCurrency(calculated, { hideZero: true })}</Numbers1>
              </div>
            </div>

            <div className={styles.cashBack}>
              <span className={Typography.h4}>=</span>
              <div className={styles.reimburseRate}>
                <span
                  className={classNames(
                    styles.yellowBg,
                    Typography.bold,
                    Typography.numbers1
                  )}
                >
                  {formatCurrency(cashBack, { hideZero: true })}
                </span>

                <span
                  className={classNames(
                    styles.yellowBg,
                    Typography.h6,
                    styles.subHeaderRegular
                  )}
                >
                  Cash Back!
                </span>
              </div>
            </div>
          </div>

          <div className={styles.footerSection}>
            <LegalBody>
              {`Annual Limit Balance Remaining for the Policy Year: ${annualLimitBalanceRemaining}`}
            </LegalBody>
          </div>
        </div>
      </div>
    </div>
  );
}

PlanCalculator.defaultProps = {
  data: {},
  values: {},
};

PlanCalculator.propTypes = {
  data: PropTypes.shape({
    copy: PropTypes.string.isRequired,
    photoUrl: PropTypes.string.isRequired,
    iconUrl: PropTypes.string.isRequired,
  }),

  values: PropTypes.shape({
    annualLimit: PropTypes.string,
    copay: PropTypes.string,
    deductible: PropTypes.string,
  }),
};

export default PlanCalculator;
