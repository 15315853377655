import { createRef, memo, useEffect } from 'react';

function SslTrustIcons() {
  const digicertSeal = createRef();

  useEffect(() => {
    window.__dcid = window.__dcid || [];

    window.__dcid.push({ cid: 'DigiCertClickID_uuafwuYp', tag: 'uuafwuYp' });

    (function () {
      const cid = window.document.createElement('script');
      cid.async = true;
      cid.src = '//seal.digicert.com/seals/cascade/seal.min.js';

      const s = window.document.getElementsByTagName('script');
      const ls = s[s.length - 1];

      ls.parentNode.insertBefore(cid, ls.nextSibling);
    })();
  }, []);

  return (
    <div
      id='DigiCertClickID_uuafwuYp'
      ref={digicertSeal}
      data-testid='digicert'
    ></div>
  );
}

export default memo(SslTrustIcons);
