import {
  ARF_MEOW_PHONE_NUMBER,
  FORMATTED_PHONE_NUMBER,
  getIsLoggedIn,
  PHONE_NUMBER_LINK,
} from '@pumpkincare/shared';
import { LegalBody, Link } from '@pumpkincare/shared/ui';

import styles from './billing-info.module.css';

export default function BillingInfo({ isAnnualCharged }) {
  const icon = isAnnualCharged
    ? '/assets/quote-flow/checkout/dog-savings.png'
    : '/assets/quote-flow/checkout/call-center.png';

  return (
    <div className={styles.annualBillingContainer}>
      <img src={icon} role='presentation' className={styles.billingIcon} alt='' />

      <div className={styles.containerSpacing}>
        {getIsLoggedIn() ? (
          <>
            <LegalBody isBold>
              {isAnnualCharged
                ? 'Cue the tailwags! Your fee was waived!'
                : 'Want to pay for insurance annually & get this fee waived?'}
            </LegalBody>

            <LegalBody>
              {isAnnualCharged ? (
                'You just saved $24.00 a year.'
              ) : (
                <>
                  We can help you change from monthly to annual payments!
                  <br />
                  <Link href={PHONE_NUMBER_LINK}>
                    <LegalBody
                      isBold
                      className={styles.linkColor}
                    >{`${ARF_MEOW_PHONE_NUMBER} (${FORMATTED_PHONE_NUMBER})`}</LegalBody>
                  </Link>
                </>
              )}
            </LegalBody>
          </>
        ) : (
          <>
            <LegalBody isBold>
              {isAnnualCharged
                ? 'Cue the tailwags! Your fee was waived!'
                : 'Want to get this installment fee waived?'}
            </LegalBody>

            <LegalBody>
              {isAnnualCharged
                ? 'You just saved $24.00 a year.'
                : 'Just switch from monthly payments to annual payments above!'}
            </LegalBody>
          </>
        )}
      </div>
    </div>
  );
}
