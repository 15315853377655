import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Body1, Typography } from '@pumpkincare/shared/ui';

import styles from './plan-vet-module.css';

function PlanVetModule({ vetName, pet, icon }) {
  return (
    <div className={styles.container}>
      <div className={styles.topIconContainer}>
        <img src={icon} className={styles.topIcon} alt='' />
      </div>

      <div className={styles.content}>
        <span className={classNames(Typography.h3, styles.title)}>
          {vetName
            ? `How about visits to ${vetName} if ${pet.petName} is hurt or sick?`
            : 'Don’t have a vet? Pumpkin plans cover eligible care at ANY licensed vet!'}
        </span>

        <Body1 className={styles.subTitle}>
          {vetName
            ? 'Pumpkin plans cover eligible care at your primary vet, plus any licensed vet, specialist, or emergency clinic in the US & CAN.'
            : 'That includes any licensed vet, specialist, or emergency clinic in the US and CAN.'}
        </Body1>
      </div>
    </div>
  );
}

PlanVetModule.defaultProps = {
  vetName: '',
  icon: '',
};

PlanVetModule.propTypes = {
  vetName: PropTypes.string,
  pet: PropTypes.object.isRequired,
  icon: PropTypes.string,
};
export default PlanVetModule;
