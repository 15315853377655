import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';

import {
  analyticsTrack,
  INITIATE_EXPERIMENT,
  planSelectionVersion,
  VISITED_PLAN_PAGE,
} from '@pumpkincare/analytics';
import {
  getPlanVersion,
  getProductCanUpsellPrevent,
  getProductHasPrevent,
  isPrevent4,
  usePetPlanData,
  usePlan,
  useProductsByState,
} from '@pumpkincare/plans';
import {
  getQuoteActivePet,
  getQuotePartner,
  getQuotePolicyState,
  getQuotePolicyZipCode,
  getQuoteVetId,
  useGoToCheckout,
  useMutatePetPlan,
  useQuote,
} from '@pumpkincare/quotes';
import {
  capitalize,
  getIsLoggedIn,
  PET_AGE_OPTIONS,
  useBooleanInput,
} from '@pumpkincare/shared';
import {
  Body1,
  Body2,
  ButtonStyles,
  KeyboardArrowDown,
  LegalBody,
  Modal,
  NotifyMessage,
  Typography,
} from '@pumpkincare/shared/ui';
import { getUserId, useUssr } from '@pumpkincare/user';

import { Paths } from '../../../app-shell';
import { getIdentityId } from '../../../identity/selectors';
import { getQuotesActiveId } from '../../../quotes/selectors';
import goToAddNewPet from '../../thunks/go-to-add-new-pet';
import CompareTable from '../compare-table';
import MoneyBackGuarantee from '../money-back-guarantee';
import NotCovered from '../not-covered';
import PepPicker from '../pep-picker/pep-picker';
import Pep3PickerDetails from '../pep3-picker-details';
import Pep4PickerDetails from '../pep4-picker-details';
import PlanCalculator from '../plan-calculator';
import PlanHowItWorks from '../plan-how-it-works';
import PlanHowItWorksLegacy from '../plan-how-it-works-legacy';
import PlanMidPageNavbar from '../plan-mid-page-navbar';
import PlanNotCovered from '../plan-not-covered';
import PlanPepUpsell from '../plan-pep-upsell/plan-pep-upsell';
import PlanRoyalty from '../plan-royalty';
import PlanStatistics from '../plan-statistics';
import PlanStatisticsLegacy from '../plan-statistics-legacy';
import PlanVetModule from '../plan-vet-module';
import PlanWhatsCovered from '../plan-whats-covered';
import QuoteEditModal from '../quote-edit-modal';
import SlideOutPanel from '../slide-out-panel';
import StickyCta from '../sticky-cta';
import DropdownNav from './dropdown-nav';
import Picker from './picker';
import StickyBar from './sticky-bar';
import WaveDelimiter from './wave-delimiter';

import styles from './ecomm-view-v2.css';

function EcommViewV2({ planPageContent }) {
  const isLoggedIn = getIsLoggedIn();

  const goToCheckout = useGoToCheckout(Paths.Checkout);

  const {
    calculator,
    coverageDetails: planCoverageDetails,
    pepCallout,
    howItWorks,
    whyPetInsurance,
    preExConditions,
    royalty,
    pickerInfo: { imageUrl: pickerImage },
    vetVisits,
  } = planPageContent;

  const {
    punks594BreedSpeciesOnPlanPage,
    suppressMaine,
    punks363EditSummary,
    punks947PlanPageDesignOptimization,
  } = useFlags();

  const dispatch = useDispatch();
  const activeId = useSelector(getQuotesActiveId);
  const identityId = useSelector(getIdentityId);

  const { data: quoteData } = useQuote();
  const activePet = getQuoteActivePet(quoteData)(activeId);
  const policyZipcode = getQuotePolicyZipCode(quoteData);
  const policyState = getQuotePolicyState(quoteData);
  const vetId = getQuoteVetId(quoteData);
  const quotePartner = getQuotePartner(quoteData);

  // forcing to fetch all necessary data here so it's available in cache when we render child components
  const { data: petPlanData } = usePetPlanData({
    petId: activePet?.id,
    identityId,
    vetId,
  });
  const {
    activeValues,
    insurancePicker: { activePrice, recommendations },
  } = petPlanData;

  const { data: userData } = useUssr();
  const userId = getUserId(userData);

  const { data: planData } = usePlan(
    activePet?.petAge,
    activePet?.petBreedSpecies,
    userId,
    vetId
  );
  const versionNumber = getPlanVersion(planData);

  const { data: productsByStateData } = useProductsByState(policyState);
  const productHasPrevent = getProductHasPrevent(productsByStateData);
  const productCanUpsellPrevent = getProductCanUpsellPrevent(productsByStateData);

  const { mutateAsync: mutatePetPlan } = useMutatePetPlan();

  const [isSlideOpen, setSlideOpen] = useState(false);
  const [addAPetScreen, showAddAPetScreen] = useState(false);
  const [stickyCtaVisibility, setStickyCtaVisibility] = useState(false);
  const [isCompareTableOpen, toggleCompareTableOpen] = useBooleanInput(false);
  const [isEditQuoteOpen, toggleEditQuoteOpen] = useBooleanInput(false);

  const postPickerRef = useRef();
  const whatsCoveredRef = useRef();
  const whatsNotCoveredRef = useRef();
  const howItWorksRef = useRef();
  const whyPetInsuranceRef = useRef();

  const {
    copay: { id: copayId },
    annual_limit: { id: annualLimitId },
    deductible: { id: deductibleId },
  } = recommendations || {};
  const suppressMainePepFlyOut = policyState === 'ME' && suppressMaine;
  const addToCartButtonText = 'Select This Plan';

  useEffect(() => {
    document.body.style.overflow = isSlideOpen ? 'hidden' : 'unset';

    return function cleanup() {
      document.body.style.overflow = 'unset';
    };
  }, [isSlideOpen]);

  useEffect(() => {
    analyticsTrack({
      category: 'Visited Plan Page',
      event: VISITED_PLAN_PAGE,
      partner: quotePartner,
    });
  }, [quotePartner]);

  useEffect(() => {
    function handleScroll() {
      if (postPickerRef.current) {
        const { offsetTop } = postPickerRef.current;

        if (window.scrollY > offsetTop) {
          setStickyCtaVisibility(true);
        } else {
          setStickyCtaVisibility(false);
        }
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [postPickerRef]);

  // segment analytics for plan page
  useEffect(() => {
    if (annualLimitId && versionNumber) {
      analyticsTrack(
        planSelectionVersion(
          annualLimitId,
          copayId,
          deductibleId,
          versionNumber,
          vetId
        )
      );
    }
  }, [annualLimitId, copayId, deductibleId, versionNumber, vetId]);

  useEffect(() => {
    analyticsTrack({
      category: 'Personalized Breed Definitions',
      event: INITIATE_EXPERIMENT,
      label: punks594BreedSpeciesOnPlanPage ? 'b_test' : 'a_control',
    });
  }, [punks594BreedSpeciesOnPlanPage]);

  useEffect(() => {
    analyticsTrack({
      category: 'Plan Page Edit Summary',
      event: INITIATE_EXPERIMENT,
      label: punks363EditSummary ? 'b_test' : 'a_control',
    });
  }, [punks363EditSummary]);

  useEffect(() => {
    analyticsTrack({
      category: 'Q323 Design Updates',
      event: INITIATE_EXPERIMENT,
      label: punks947PlanPageDesignOptimization ? 'b_test' : 'a_control',
    });
  }, [punks947PlanPageDesignOptimization]);

  function handleAddToCart() {
    analyticsTrack({
      category: 'Select Plan',
      event: 'Click Select Plan',
      label: 'Select Plan',
    });

    if (isLoggedIn && (!productHasPrevent || activePet.hasPrevent)) {
      goToCheckout();
    } else {
      window.scrollTo(0, 0);
      setSlideOpen(true);
    }
  }

  function handlePreventChange() {
    analyticsTrack({
      event: activePet.hasPrevent
        ? 'Remove prevent from plan'
        : 'Add prevent to plan',
      category: 'Plan Page',
    });

    mutatePetPlan({
      pet: { ...activePet, hasPrevent: !activePet.hasPrevent },
      plan: planData,
    }).then(() => {
      if (isLoggedIn) {
        goToCheckout();
      }
    });
  }

  function handleOnAdd() {
    analyticsTrack({
      category: 'Add Preventive Essentials',
      event: 'Click Add To Plan',
      label: 'Add Preventive Essentials',
    });

    if (productHasPrevent) {
      handlePreventChange();
    } else if (isLoggedIn) {
      goToCheckout();
    } else {
      showAddAPetScreen(true);
    }

    window.scrollTo(0, 0);
  }

  function handleOnDecline() {
    analyticsTrack({
      category: 'Add Preventive Essentials',
      event: 'Click No Thanks',
      label: 'Add Preventive Essentials',
    });

    if (isLoggedIn) {
      goToCheckout();
    } else {
      showAddAPetScreen(true);
    }

    window.scrollTo(0, 0);
  }

  function handleBackDropClose() {
    showAddAPetScreen(false);
    setSlideOpen(false);
  }

  function handleAddPetClick() {
    analyticsTrack({
      category: 'Want to add another pet?',
      event: 'Click Add Another Pet',
      label: 'Want to add another pet?',
    });

    dispatch(goToAddNewPet());
    dispatch(push(Paths.Intro));
  }

  function handleGoToCheckoutClick() {
    analyticsTrack({
      category: 'Want to add another pet?',
      event: 'Click No Thanks',
      label: 'Want to add another pet?',
    });

    goToCheckout();
  }

  function scrollIntoView(elem) {
    elem.style.scrollMargin = '40px';
    elem.scrollIntoView({
      behavior: 'smooth',
    });
  }

  return (
    <>
      {isEditQuoteOpen ? <QuoteEditModal onClose={toggleEditQuoteOpen} /> : null}

      {punks363EditSummary ? (
        <div className={styles.topBanner}>
          <LegalBody className={styles.topBannerText}>
            {`${activePet.petBreedName} • ${
              PET_AGE_OPTIONS.find(({ value }) => value === activePet.petAge).label
            } • ${capitalize(activePet.petGender)} • ${policyZipcode} `}

            {quoteData.vet.vet_name ? (
              <span className={styles.vetName}>
                {`•  ${quoteData.vet.vet_name}`}
              </span>
            ) : null}

            {punks363EditSummary ? (
              <button
                className={classNames(ButtonStyles.cta, styles.editQuoteButton)}
                onClick={toggleEditQuoteOpen}
              >
                EDIT
              </button>
            ) : null}
          </LegalBody>
        </div>
      ) : null}

      {punks947PlanPageDesignOptimization ? (
        <StickyBar
          onClick={() => window.scrollTo({ behavior: 'smooth', top: 0 })}
          visibility={stickyCtaVisibility}
        >
          <button
            className={classNames(ButtonStyles.primary, styles.selectPlanButton)}
            onClick={handleAddToCart}
          >
            <b>Select – ${activePrice} / mo</b>
          </button>

          <DropdownNav
            items={[
              {
                label: 'Top of Page',
                callback: () => window.scrollTo(0, 0),
              },
              {
                label: 'What’s Covered',
                callback: () => scrollIntoView(whatsCoveredRef.current),
              },
              {
                label: 'What’s Not Covered',
                callback: () => scrollIntoView(whatsNotCoveredRef.current),
              },
              {
                label: 'How Pumpkin Works',
                callback: () => scrollIntoView(howItWorksRef.current),
              },
              {
                label: 'Why Pet Insurance?',
                callback: () => scrollIntoView(whyPetInsuranceRef.current),
              },
            ]}
          />
        </StickyBar>
      ) : (
        <StickyCta
          pet={activePet}
          price={activePrice}
          onCtaClick={handleAddToCart}
          onClick={() => window.scrollTo({ behavior: 'smooth', top: 0 })}
          visibility={stickyCtaVisibility}
        />
      )}

      {isCompareTableOpen ? (
        <Modal onClose={toggleCompareTableOpen}>
          <CompareTable onClose={toggleCompareTableOpen} />

          <div className={styles.compareFooterSection}>
            <button
              onClick={toggleCompareTableOpen}
              className={classNames(ButtonStyles.cta)}
            >
              Back to Plan Details
            </button>
          </div>
        </Modal>
      ) : null}

      <Picker
        buttonText={addToCartButtonText}
        data-testid='select-phi'
        image={pickerImage}
        onAddToCart={handleAddToCart}
      />

      <div ref={postPickerRef} />

      <SlideOutPanel
        classes={{ container: styles.slideOutContainer }}
        show={isSlideOpen}
        onClose={handleBackDropClose}
      >
        <div className={styles.notifyMessage}>
          {activePet.hasPrevent ? (
            <NotifyMessage
              icon={'/assets/images/iconImages/check-scout-navy.svg'}
              message={`${activePet?.petName}'s Preventive Essentials Added to Cart`}
            />
          ) : null}

          {!activePet.hasPrevent && productHasPrevent && !addAPetScreen ? (
            <NotifyMessage
              icon={'/assets/images/iconImages/check-scout-navy.svg'}
              message={`${activePet?.petName}'s Insurance Added to Cart`}
            />
          ) : null}
        </div>

        <div className={styles.slideOutContent}>
          <div className={styles.slideOutBody}>
            {productHasPrevent === false && !suppressMainePepFlyOut ? (
              <div className={styles.unavailable}>
                <img src='/assets/images/illustrations/multipet/map.png' alt='' />

                <div>
                  <Body2 isBold className={styles.pepPack}>
                    Preventive Essentials Pack is not available in your state at this
                    time.
                  </Body2>

                  <LegalBody className={styles.bodyText}>
                    You’ll still get pawesome accident & illness coverage for{' '}
                    {activePet?.petName} with your insurance. You just won’t have the
                    option to get yearly preventive care covered (e.g. vaccines,
                    wellness exam fees, heartworm or fecal tests). Preventive
                    Essentials is not insurance, but an optional wellness package
                    available in some states.
                  </LegalBody>
                </div>
              </div>
            ) : null}

            {!productHasPrevent ||
            ((activePet.hasPrevent || addAPetScreen) && !isLoggedIn) ? (
              <div>
                <img
                  src={'/assets/images/circle-cat-dog.png'}
                  className={styles.topIconDogCat}
                  alt=''
                />

                <div className={styles.newPetBody}>
                  <span
                    className={classNames(Typography.h3, styles.newPetBodyTitle)}
                  >
                    Want to add another pet?
                  </span>

                  <Body1 className={styles.newPetBodyText}>
                    We have a pawsome 10% discount for covering multiple fur babies.
                  </Body1>
                </div>

                <div className={styles.newPetButtonSection}>
                  <button
                    data-testid='add-a-pet'
                    className={classNames(ButtonStyles.primary, styles.btnStyle)}
                    onClick={handleAddPetClick}
                  >
                    Add Another Pet
                  </button>

                  <button
                    data-testid='no-add-pet'
                    className={classNames(ButtonStyles.secondary, styles.btnStyle)}
                    onClick={handleGoToCheckoutClick}
                  >
                    No Thanks - Go to Checkout
                  </button>
                </div>
              </div>
            ) : (
              <>
                <img
                  alt=''
                  src={'/assets/images/iconImages/design-star.png'}
                  className={styles.topIconStar}
                />

                <PepPicker
                  plan={planData}
                  quoteData={quoteData}
                  activePet={activePet}
                  onAdd={handleOnAdd}
                  onRemove={handleOnDecline}
                />

                <div className={styles.blueCircle}>
                  <KeyboardArrowDown className={styles.arrowIcon} />
                </div>

                {isPrevent4(planData) && activePet ? (
                  <Pep4PickerDetails
                    plan={planData}
                    activePet={activePet}
                    onAdd={handleOnAdd}
                    onRemove={handleOnDecline}
                  />
                ) : (
                  <Pep3PickerDetails
                    pet={activePet}
                    onAdd={handleOnAdd}
                    onRemove={handleOnDecline}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </SlideOutPanel>

      {punks947PlanPageDesignOptimization ? null : (
        <WaveDelimiter
          style={{ zIndex: 0 }}
          className={styles.wayFinding}
          id={'contentStart'}
          line
          onArrowClick={() => {
            document
              .getElementById('contentStart')
              .scrollIntoView({ behavior: 'smooth' });
          }}
        />
      )}

      {punks947PlanPageDesignOptimization ? (
        <>
          <PlanMidPageNavbar
            whatsRef={whatsCoveredRef}
            whatsNotCovRef={whatsNotCoveredRef}
            whyPetInsRef={whyPetInsuranceRef}
            howItWorksRef={howItWorksRef}
            policyState={policyState}
          />

          <WaveDelimiter benjiBlueToBlueClues />
        </>
      ) : null}

      <div ref={whatsCoveredRef} />

      <PlanWhatsCovered
        coverageDetails={planCoverageDetails}
        onCompareClick={toggleCompareTableOpen}
      />

      {!punks947PlanPageDesignOptimization && royalty.enabled ? (
        <PlanRoyalty {...royalty} />
      ) : null}

      {policyState !== 'ME' ? (
        <>
          <div ref={whatsNotCoveredRef} />

          <PlanNotCovered
            petBreedSpecies={activePet.petBreedSpecies}
            petAge={activePet.petAge}
          />
        </>
      ) : null}

      {productCanUpsellPrevent &&
      policyState !== 'ME' &&
      !punks947PlanPageDesignOptimization ? (
        <PlanPepUpsell
          petBreedSpecies={activePet.petBreedSpecies}
          pepCallout={pepCallout}
        />
      ) : null}

      {punks947PlanPageDesignOptimization ? (
        <>
          <div ref={howItWorksRef} />

          <PlanHowItWorks
            howItWorks={howItWorks}
            topIcon={vetVisits}
            petName={activePet.petName}
            calculatorData={calculator}
            values={activeValues}
            vetName={quoteData.vet.vet_name}
          />

          <WaveDelimiter bluesCluesToHuckleberry reverse />

          <div ref={whyPetInsuranceRef} />

          <PlanStatistics
            whyPetInsurance={whyPetInsurance}
            preExConditions={preExConditions}
            petBreedSpecies={activePet.petBreedSpecies}
            state={policyState}
            royaltyData={royalty}
          />
        </>
      ) : (
        <>
          {policyState === 'ME' ? <NotCovered plan={planData} /> : null}

          <PlanVetModule
            vetName={quoteData.vet.vet_name}
            pet={activePet}
            icon={vetVisits}
          />

          <WaveDelimiter bluesCluesToHuckleberry />

          <PlanCalculator data={calculator} values={activeValues} />

          <WaveDelimiter benjiBlue />

          <PlanHowItWorksLegacy howItWorks={howItWorks} />

          <div ref={whyPetInsuranceRef} />

          <PlanStatisticsLegacy
            whyPetInsurance={whyPetInsurance}
            preExConditions={preExConditions}
            petBreedSpecies={activePet.petBreedSpecies}
            state={policyState}
          />
        </>
      )}

      {policyState === 'NY' ? null : (
        <MoneyBackGuarantee policyState={policyState} />
      )}
    </>
  );
}

export default EcommViewV2;
