export const getQuotes = state => state.quotes;
export const getQuotesPets = state => state.quotes.pets;
export const getQuotesActiveId = state => state.quotes.activeID;
export const getQuotesMode = state => state.quotes.mode;
export const getQuotesPetsOrder = state => state.quotes.petsOrder;
export const getQuotesTrackingId = state => state.quotes.trackingId;
export const getQuotesId = state => state.quotes.id;
export const getQuotesEmail = state => state.quotes.email;
export const getQuotesFirstName = state => state.quotes.firstName;
export const getQuotesLastName = state => state.quotes.lastName;

export const getQuotesRegisterQuoteStatus = state =>
  state.quotes.registerQuoteStatus;

export const getShippingSameAsBilling = state =>
  state.quotes.isShippingSameAsBilling;
export const getQuotesShippingAddress = state => state.quotes.shippingAddress;
export const getQuotesBillingAddress = state => state.quotes.billingAddress;
export const getQuotesPaymentInfo = state => state.quotes.paymentInfo;

export const getQuotesPartner = state => state.quotes.partner;
