import classNames from 'classnames';

import { Body1 } from '@pumpkincare/shared/ui';

import styles from './plan-pep-upsell.module.css';

function PlanPepUpsell({ pepCallout }) {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.contentText}>
          <Body1 isBold className={classNames(styles.bodyText)}>
            {pepCallout.header}
          </Body1>

          <Body1 className={styles.bodyText}>{pepCallout.copy}</Body1>
        </div>

        <div className={styles.contentImage}>
          <div className={styles.iconContainer}>
            <img src={pepCallout.imageUrl} alt='' className={styles.icon} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlanPepUpsell;
